require('../css/home.scss');

$(document).ready(function(){

    $.ajax({
        url: "/ajax/posts/instagram"
    }).done(function(data){
        $(".section-instagram").css('background', 'transparent').append(data.content);
    }).fail(function(){
        $(".section-instagram").css('background', 'transparent').remove();
    });

});
